import { Button, Column, GridContainer, Progress, Row, Typography } from '@vp/swan'
import { useState } from 'react'
import { ReviewHistogramItem } from '../../types'
import { trackEvent } from '../../utils/tracking'

interface IHistogram {
  histogramItem: ReviewHistogramItem
  filterReview: (arg0: number) => void
  removeFilter: () => void
  isSelected: boolean
}
export const HistogramItem: React.FC<IHistogram> = ({
  histogramItem,
  filterReview,
  removeFilter,
  isSelected,
}) => {
  const { filterByRatingValue } = histogramItem

  const [hoverState, setHoverState] = useState(false) // for hover

  const changeFiltering = (newFilterByRatingValue: number | undefined) => {
    filterReview(newFilterByRatingValue as number)
    trackEvent({
      eventName: 'Ratings Reviews Filtered',
      properties: {
        label: `${newFilterByRatingValue ?? filterByRatingValue} Stars`,
      },
    })
  }

  return (
    <Button
      disabled={histogramItem.rating === 0}
      covering
      width='full-width'
      skin='unstyled'
      bgc={hoverState || isSelected ? 'info' : undefined}
      paddingY='2'
      paddingX='2'
      onMouseOver={() => setHoverState(true)}
      onMouseOut={() => setHoverState(false)}
      onClick={() =>
        isSelected
          ? removeFilter()
          : changeFiltering(histogramItem.filterByRatingValue)}
    >
      <GridContainer
        style={{ width: '100%', position: 'relative' }}
        marginBottom={2}
      >
        <Row>
          <Column span={3} spanXs={3}>
            <Typography fontSkin='body-small' textAlign="center">
              {histogramItem.label}
            </Typography>
          </Column>
          <Column span={6} spanXs={6} verticallyCenterContent>
            <Progress
              aria-label={`${histogramItem.width}%`}
              value={histogramItem.width.replace('%', '')}
            />
          </Column>
          <Column span={3} spanXs={3}>
            <Typography fontSkin='body-small' textAlign="center">
              {histogramItem.rating}
            </Typography>
          </Column>
        </Row>
      </GridContainer>
    </Button>
  )
}
